import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import Chat from "vue3-beautiful-chat";

// plugin css
import "./assets/css/bootstrap.min.css";
import "./assets/css/plugin.min.css";

// template style
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/darkmode.css";

// template JavaScript
import "./assets/js/jquery.min.js";
import "./assets/js/bootstrap.bundle.min.js";
import "./assets/js/vendor/modernizr-3.5.0.min.js";
import "./assets/js/plugin.min.js";
import "./assets/js/preloader.js";
import "./assets/js/dark-mode.js";

const app = createApp(App);

app.use(router);
app.mount("#app");
app.use(Chat);

$(window).on("load", function () {
  var delayMs = 2000; // delay in milliseconds
  setTimeout(function () {
    $("#leadModal").modal("show");
  }, delayMs);
});
