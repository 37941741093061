<template>
  <div class="onloadpage" id="page_loader">
    <div class="pre-content" style="text-align: center">
      <div class="logo-pre">
        <img src="./assets/images/imd_icon-180x180.png" alt="Logo" />
      </div>
      <div class="pre-text- text-radius text-light text-animation bg-b">
        Immersive Mobile Designs Pvt Ltd
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script setup></script>

<style scoped></style>
